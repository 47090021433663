

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/hamburgers.css';
/*IMPORT SCSS*/
import '../styles/index.scss';



/*MAIN APPLICATION
import {dada} from "./test.js";
var dii = "";*/


$(document).ready(function(){

    $( ".slick-initor" ).each(function() {
      $(this).slick();
    });

    $('.banner-carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
    });

    $('.bottom-carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
    });

    $('.product-carousel').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                arrows: false,
                centerMode: true,
                centerPadding: '10%',
            }
        }
    ]
    });


});

jQuery('.hamburger').click(function(){
  	$('body').toggleClass("disablescroll");
  	$('.hamburger').toggleClass("is-active");
	$('.meny').toggleClass("menytoggler");
//  $('nav').toggleClass('expandednav');
});
